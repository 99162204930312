import * as grid from '../styles/grid.module.scss';
import ArticleLayout from "../components/articleLayout/ArticleLayout";
import Caption from "../components/caption/Caption";
import PageTitle from "../components/contentHeadings/PageTitle";
import Quote from "../components/quote/Quote";
import React from 'react';
import SeeAvailableButton from "../components/button/SeeAvailableButton";
import Seo from "../components/Seo";
import SubHeading from "../components/contentHeadings/SubHeading";
import TertiaryHeading from "../components/contentHeadings/TertiaryHeading";
import { Link } from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import outputPixelDensities from "../data/defaultPixelDensities";

const Refill = () => {
    const bad1 = <StaticImage
        src={"../images/darlige_vipper/darlige_vipper1.jpg"}
        alt={"Vippeextensions som er dårlig lagt"}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64)"}
        width={900}
        outputPixelDensities={outputPixelDensities}
    />;

    const bad2 = <StaticImage
        src={"../images/darlige_vipper/darlige_vipper2.jpeg"}
        alt={"Vippeextensions som er dårlig lagt"}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64)"}
        width={900}
        outputPixelDensities={outputPixelDensities}
    />;

    const bad3 = <StaticImage
        src={"../images/darlige_vipper/darlige_vipper3.jpeg"}
        alt={"Dårlige lagt vippeextensions som har gjort det vanskelig å vaske."}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64)"}
        width={900}
        outputPixelDensities={outputPixelDensities}
    />;

    const bad4 = <StaticImage
        src={"../images/darlige_vipper/darlige_vipper4.jpeg"}
        alt={"De samme vippene som i det forrige bildet, men etter at de er blitt renset ordentlig."}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64)"}
        width={900}
        outputPixelDensities={outputPixelDensities}
    />;

    const bad5 = <StaticImage
        src={"../images/darlige_vipper/darlige_vipper5.jpg"}
        alt={"Dårlige lagt vippeextensions som har gjort det vanskelig å vaske."}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64)"}
        width={900}
        outputPixelDensities={outputPixelDensities}
    />;

    const bad6 = <StaticImage
        src={"../images/darlige_vipper/darlige_vipper6.jpg"}
        alt={"De samme vippene som i det forrige bildet, men etter at de er blitt renset ordentlig."}
        sizes={"(min-width: 960px): 900px, calc(100vw - 64)"}
        width={900}
        outputPixelDensities={outputPixelDensities}
    />;

    return(
        <ArticleLayout>
            <Seo title={"Påfyll fra andre salonger"} />
            <PageTitle>Påfyll fra andre salonger</PageTitle>
            <SubHeading>Kan jeg fylle på hos dere, hvis jeg har tatt vippeextensions i en annen salong?</SubHeading>
            <p>Dette går nesten alltid helt fint! Hvis du har godt utførte extensions så er det null problem for oss å fylle på. Unntakene er om dine naturlige vipper har blitt limt sammen. Se mer om dette nedenfor.</p>
            <Quote>Tips: Pass på at du bestiller riktig påfyll. Hvis du kommer med veldig få eller helt uten vippeextensions trenger vi nok tid til å sette på et nytt sett (og du må derfor velge “nytt sett” i bookingprogrammet). Vi rekker ikke sette på et helt nytt sett på påfyllstid.</Quote>
            <SeeAvailableButton>Se ledige påfyllstimer</SeeAvailableButton>
            <SubHeading>Sammenlimte vipper</SubHeading>
            <p>Vi kan ikke fylle på vippeextensions hvis man kommer fra et sted som har limt flere av de naturlige vippene sammen i hverandre eller fast i huden. Det er ikke mulig å ta nye extensions på hår som er fanget i en limklump sammen med andre hår, og vi har ikke noen annen mulighet enn å fjerne de vippeextensions-ene som må fjernes (noen ganger alle) før eventuelt nytt sett. Hvis du da har bestilt kun påfyll får vi ikke dekket 100% av settet. Da er det beste å bestille fjerning + nytt sett. </p>
            <p>Er du i tvil kan du f.eks. sende oss et bilde av vippene på en facebook-melding, så hjelper vi deg gjerne!</p>
            <div className={grid.container}>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        { bad1 }
                        <figcaption>
                            <Caption>Disse kan vi ikke fylle på. Det er lite naturlige hår som ikke allerede er limt fast i hverandre, og det er blitt brukt for mye lim. Hvis vi ikke fjerner vippene kan vi ikke fylle på noen ting. Man kan også se en oppsamling av sminke, fett og hudpartikler i de store klumpene til venstre.</Caption>
                        </figcaption>
                    </figure>
                </div>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        { bad2 }
                        <figcaption>
                            <Caption>Rotete og kaotiske vipper, i tillegg til for mye lim og hår som er klistret til hverandre. Man kan se at de naturlige vippene allerede har tatt skade. Slike vipper vil klø, stikke og være til ubehag i tillegg til å ødelegge de naturlige vippene.</Caption>
                        </figcaption>
                    </figure>
                </div>
            </div>
            <SubHeading>Fjerning av vippeextensions</SubHeading>
            <Quote>
                <TertiaryHeading>Tegn på at vippeextensions må fjernes</TertiaryHeading>
                <p>1. Hvis dine naturlige vipper er limt i hverandre, noe som kan skje hvis det er brukt unødvendige mengder lim, vil disse hårene rive hverandre ut når de vokser. Vipper kan da bli nappet ut av naboen sin, og man vil få “hull” i vippene.</p>
                <p className={'no-margin'}>2. Er dine vippeextensions ubehagelige? Det skal de ikke være!</p>
            </Quote>
            <p>Vippeextensions skal ikke stikke deg, verken på huden, våtkanten eller i øyet. Vippeextensions skal sitte ca 0,5-1mm ut fra våtkanten, og ikke komme nær huden. Du skal ikke kunne merke dem verken under behandlingen eller senere. </p>
            <p>Det er veldig vanskelig å holde vippene rene hvis man har sammenlimte vipper. Les mer her om <Link to={'/rens-av-vippeextensions'}>rens av vippeextensions</Link>.</p>
            <div className={grid.container}>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        { bad3 }
                        <figcaption>
                            <Caption>Sammenlimte vipper er vanskelige å rense. Når det er store mengder lim eller sammenlimte vipper får man ikke renset huden. Dette bildet er tatt etter at vi har fjernet noen av extensionsene kunden kom inn med.</Caption>
                        </figcaption>
                    </figure>
                </div>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        { bad4 }
                        <figcaption>
                            <Caption>Dette er de samme vippene etter fjerning av alle extensions og grundig rens av vippene. Slik skal huden og vippene se ut, selv med extensions. Da unngår man at men er i faresonen for infeksjoner og midd.</Caption>
                        </figcaption>
                    </figure>
                </div>
            </div>
            <p>Du skal kunne børste grundig gjennom vippene uten å kjenne noe ubehag eller lugging. Hvis du synes det klør eller lugger kan det hende at du har fått lim på huden. Limet vi bruker til extensions er laget for hår, ikke hud! Er du usikker? Prøv å dytt vippene opp på øyelokket. Hvis du kjenner at det gjør vondt eller stikker kan det hende at du har fått lim på huden. I verste fall kan du få kjemisk brannskade, eller utvikle allergi raskt. </p>
            <div className={grid.container}>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        { bad5 }
                        <figcaption>
                            <Caption>Veldig sammenlimte vippeextensions. Disse er ca 4 uker gamle, og man kan se at de naturlige vippene vokser under. Disse vil rive hverandre ut, og man kan ende opp uten vipper.</Caption>
                        </figcaption>
                    </figure>
                </div>
                <div className={grid.xs12Sm6P8}>
                    <figure>
                        { bad6 }
                        <figcaption>
                            <Caption>Veldig skadelige vipper. Her er alle limt fast i øyelokket og hverandre. Dette kan gi kjemisk brannskade, og føre til alvorlige skader. Disse tok over 3 timer å fjerne.</Caption>
                        </figcaption>
                    </figure>
                </div>
            </div>
            <p>Hvis du synes at extensions gjør vondt bør du få dem fjernet umiddelbart. Hvis du tror du har fått sår på øyet eller huden må du kontakte lege. Har du spørsmål kan du alltid ringe oss, selv om du tok vippeextensions et annet sted. </p>
            <TertiaryHeading>Hvordan bestille fjerning?</TertiaryHeading>
            <p>Vil du bestille fjerning av vippeextensions? Du kan bestille dette i vårt bookingprogram, men tiden som settes av automatisk er beregnet på fjerning av våre extensions. Vi setter av 30 min, men det tar vanligvis kortere tid enn dette. Er vippeextensions-ene dine fra et annet sted, og sammenlimt, vil vi kunne trenge mer tid og du bør heller ta kontakt med oss. Mistenker du at det er brukt for mye lim, seg kan du gjerne sende oss en melding med et bilde. Prisen for fjerningen blir uansett den samme, men vi har i de aller verste tilfellene opplevd at det har tatt hele 2-3 timer å fjerne sammenlimte vippeextensions! </p>
        </ArticleLayout>
    )
};

export default Refill;